export const questions = [
  // {
  //   id: 1,
  //   question: "What’s the mint price and supply?",
  //   answer:
  //     "There will be 9,999 Alpha Aliens in total, 99 are retained by the team and for marketing, 100 are reserved for the community as prizes for contests and giveaways. For the WhiteList 1,000 Alpha Aliens will be reserved (2x wallet) and will be priced at 0.01 ETH. This leaves 8,800 Alpha Aliens for public sale which will be priced at 0.025 ETH.",
  // },
  {
    id: 2,
    question: "What’s the mint date?",
    answer:
      "Public Launch will take place on Thursday 5th May 20:00 UTC. WhiteList Pre-Sale will be the same day and the exact time will be announced in our discord a few days before the launch.",
  },
  {
    id: 3,
    question: "Where can I buy an Alpha Aliens?",
    answer:
      "Alpha Aliens can be minted via our website. You will need a Metamask wallet to connect to the website and complete the purchase.",
  },
  {
    id: 4,
    question: "Where can I meet the team and the community?",
    answer:
      "To get all the information and join the community, go to Alpha Space Station on discord.",
  },
  // {
  //   id: 5,
  //   question: "Where can I check the Alpha Aliens smart contract?",
  //   answer:
  //     "https://etherscan.io/address/0x86604Ca740367dc868829AAAbC927d3CB87865Cf#code",
  // },
  // {
  //   id: 6,
  //   question: "Why mint in $LOOKS beside $ETH?",
  //   answer:
  //     "The team is bullish on the LooksRare platform. The amount of $LOOKS that will be collected during the public sale will be entirely staked on the LooksRare platform and the revenues will be allocated to the treasury.",
  // },
  // {
  //   id: 7,
  //   question: "hy should I mint a ?",
  //   answer:
  //     "We want to create an active and productive environment where each member can contribute if he/she wants, along with creating value for the holders with the holders. Different experitizes are going to be necessary for the success of the DAO and will be rewarded by the DAO itself. Beside this, the art is very cool!",
  // },
  // {
  //   id: 8,
  //   question: "Who is behind this project?",
  //   answer:
  //     "We are 2 crypto lovers excited about decentralization and we met working in the team of an IT company. We are full-time committed to crypto and blockchain since early 2018.The team is rounded out by a project designer and a DeFi maximalist who is also a marketing expert. We are all NFTs addicts.",
  // },
];
