import React, { useState } from "react";

const MyAccordion = ({ element }) => {
  const { question, answer } = element;
  const [show, setShow] = useState(false);
  return (
    <div className="accordion-item mb-4">
      <h1 className="accordion-header">
        <div
          className="faq-button p-3 d-flex justify-content-between align-items-center"
          onClick={() => setShow(!show)}
        >
          <div className="question">{question}</div>
        </div>
      </h1>
      {show && <div className="accordion-body">{answer}</div>}
    </div>
    // <div className="section mb-2 p-2" id="faq">
    // 	<div className="answer-qestion mb-2 p-3">
    // 		<div
    // 			className="question-flex mb-3 d-flex justify-content-between align-items-center"
    // 			onClick={() => setShow(!show)}
    // 		>
    // 			<div className="question">{question}</div>
    // 			{show ? <i class="fas fa-minus"></i> : <i class="fas fa-plus"></i>}
    // 		</div>

    // 	</div>
    // </div>
  );
};

export default MyAccordion;
