import React from "react";
import header from "../Image/promo.gif";
import "./Header.css";
const Header = () => {
  return (
    <div className="main">
      {/* <div className="wave wave1"></div>
			<div className="wave wave2"></div>
			<div className="wave wave3"></div>
			<div className="wave wave4"></div> */}
      <div className="container py-5">
        <div className="row align-items-center" id="home">
          {/* <div className="col-lg-7 left-part p-5 col-11 mx-lg-0 mx-auto my-5">
            <div className="main-text">
              <div className="title-black my-3" data-aos="fade-up">
                WELCOME TO ALPHA ALIENS
              </div>

              <div className="subtitle-black" data-aos="fade-up">
                Tattoo Bum is a limited art collection of 10,000 assets.
                Carefully designed among more than 300 original traits, holders
                have access to exclusive Tattoo Bum drops and the opportunity to
                shape our collective future. Check our medium for the latest
                updates.
              </div>
            </div>

            <div className="main-bottom-buttom py-3">
              <a href="https://medium.com/@tattoobum" target="_blank">
                <button
                  className="button-dark mx-auto text-center"
                  data-aos="zoom-in"
                >
                  MEDIUM
                </button>
              </a>
            </div>
          </div> */}

          {/* <div className="col-lg-5 d-lg-block d-none col-11 mx-auto text-center position-part-header">
            <img
              src={header}
              alt=""
              className="img-center mx-auto img-fluid"
              data-aos="fade-up"
            />
          </div> */}
          {/* <div className="col-lg-4 d-lg-none col-11 mx-auto text-center my-5">
            <img
              src={header}
              alt=""
              className="img-center mx-auto img-fluid mb-3"
              data-aos="fade-up"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
