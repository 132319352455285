import React from "react";
import header from "../Image/about.gif";
import "./About.css";
const About = () => {
  return (
    <div className="about" id="about">
      <div className="container">
        <div className="row">
          <div className="col-11 col-lg-12 mx-auto">
            <div className="title-white text-center py-5">ALPHA ALIENS</div>
            <div
              className="subtitle-white width-part mx-auto pb-5"
              // data-aos="fade-up"
            >
              9,999 Aliens looking for the best opportunities in their Space
              Station on a journey inspired by art, fun and games! 25% of the
              proceeds from the Alpha Alien sales will go directly to build the
              Alien treasury. The treasury will be used to invest in the Alien
              community, to run member-only competitions, for marketing and for
              any other activity voted by the community to ensure growth.
            </div>
          </div>
          <div className="col-lg-11 col-11 mx-auto text-center pb-5">
            <img
              src={header}
              alt=""
              width="100%"
              className="img-center mx-auto"
              // data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
