import React, { useState } from "react";
import logo from "../Image/alien0.png";
import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsMedium } from "react-icons/bs";
import { SiGitbook } from "react-icons/si";
import "./Navbar.css";

const Navbar = () => {
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 110) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  return (
    <nav
      className={
        color
          ? "navbar navbar-expand-lg sticky-top scroll box-shadow "
          : "navbar navbar-expand-lg sticky-top withoutScroll "
      }
    >
      <div className="container py-3">
        <a className="navbar-brand" href="#home">
          <img src={logo} alt="" width="70px" className="logo img-fluid" />
        </a>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="toggler-icon top-bar"></span>
          <span className="toggler-icon middle-bar"></span>
          <span className="toggler-icon bottom-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-lg-0 mb-3">
            {/* <li className="nav-item mx-3 mb-lg-0 mb-3 mt-lg-0 mt-3">
              <a className="nav-link" href="#mint">
                Mint
              </a>
            </li> */}
            {/* <li className="nav-item mx-3 mb-lg-0 mb-3">
              <a className="nav-link" href="#sniping">
                Sniping
              </a>
            </li> */}
            {/* <li className="nav-item mx-3 mb-lg-0 mb-3">
              <a className="nav-link" href="#station">
                Alphaverse
              </a>
            </li> */}
            {/* <li className="nav-item mx-3 mb-lg-0 mb-3">
              <a className="nav-link" href="#strategy">
                $Alpha
              </a>
            </li> */}
            {/* <li className="nav-item mx-3 mb-lg-0 mb-3">
              <a className="nav-link" href="#faq">
                Faq
              </a>
            </li> */}
          </ul>
          <div className="icon-part d-flex ">
            <div className="twitter mx-3">
              <a href="https://twitter.com/AliensAlpha" target="_blank">
                <BsTwitter className="icon-btn" />
              </a>
            </div>
            <div className="discord mx-3">
              <a href="https://discord.com/invite/alphaaliens" target="_blank">
                <FaDiscord className="icon-btn" />
              </a>
            </div>
            <div className="gitbook mx-3">
              <a
                href="https://alphaaliens.gitbook.io/alpha-aliens"
                target="_blank"
              >
                <SiGitbook className="icon-btn" />
              </a>
            </div>
          </div>
          {/* <a href="" target="_blank" rel="noopener noreferrer">
            <button
              type="button"
              className="button-white mb-lg-0 ms-lg-5 ms-0 mb-5"
            >
              Connect wallet
            </button>
          </a> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
