import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";
import {
  gasFactor,
  getContractNft,
  nftAddress,
  os,
  whitelist,
} from "./smart-contracts-config.js";
import { getBlockchainData } from "./metamask-connection.js";

export const publicMintPlusWhitelistMint = async (
  setLoading,
  setWalletText,
  howMany
) => {
  getBlockchainData((account) => {
    setWalletText(account);

    account = account.toLowerCase();
    const tree = getMerkleTree();
    const root = tree.getHexRoot();
    const leaf = keccak256(account);
    const proof = tree.getHexProof(leaf);
    const verified = tree.verify(proof, leaf, root);
    if (
      whitelist.includes(account) ||
      whitelist.some((acc) => acc === account) ||
      verified
    )
      whitelistMint(setLoading, howMany);
    else publicMint(setLoading, howMany);
  });
};

export const publicMint = async (setLoading, howMany) => {
  setLoading(true);
  getBlockchainData(async (account, web3) => {
    if (!howMany || isNaN(howMany)) {
      alert("Enter some quantity to Mint");
      return;
    }
    const contract = getContractNft(web3);
    const itemPrice = await contract.methods.itemPrice().call();
    const totalPrice = (Number(itemPrice) * howMany).toString();

    // here we use code from twitter post of nft
    const method = contract.methods.purchaseTokens(howMany);
    let options = {
      from: account,
      gas: "0",
      value: totalPrice,
    };
    try {
      const estimateGasPrice1 = await method.estimateGas(options);
      const estimateGasPrice2 = Math.trunc(gasFactor * estimateGasPrice1);
      options = { ...options, gas: "" + estimateGasPrice2 };
    } catch (e) {
      let msg;
      try {
        console.log(e.message);
        let a = e.message;
        let objStr = a.substring(a.indexOf("{"), a.lastIndexOf("}") + 1);
        // console.log({ objStr });
        msg =
          JSON.parse(objStr).message ||
          JSON.parse(objStr).originalError.message;
        msg = msg.replace("err: ", "");
        msg = msg.replace("execution reverted: ", "");
      } catch (eiii) {}

      if (!msg || msg === undefined) {
        msg = "Insufficient funds";
      }
      if (msg === "Mint min 1, max 10") msg = "Mint amount exceeded";
      else if (msg === "send correct eth") msg = "Send correct ETH";
      else if (msg === "Sale is not active")
        msg = "Sale will start at 10pm UTC";

      alert(msg);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      await method.send(options).on("confirmation", (i, tx) => {
        if (i === 3) {
          setLoading(false);
          let tokenId;
          try {
            tokenId = tx.events.Transfer.returnValues.tokenId;
          } catch (e) {
            tokenId = tx.events.Transfer[0].returnValues.tokenId;
          }
          if (
            window.confirm(
              "Check your Alpha Aliens on OpenSea. Refresh the page if needed."
            )
          )
            window.location.href = `${os}/${nftAddress}/${tokenId}`;
        }
      });
    } catch (e) {
      setLoading(false);
      if (
        e.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      )
        alert("User denied transaction");
      else alert(e.message);
    }
  });
};

export const whitelistMint = async (setLoading, howMany) => {
  setLoading(true);
  getBlockchainData(async (account, web3) => {
    account = account.toLowerCase();

    if (!howMany || isNaN(howMany)) {
      alert("Enter some quantity to Mint");
      setLoading(false);
      return;
    }
    const contract = getContractNft(web3);

    const priceWhitelistMint = await contract.methods.itemPricePresales(0).call();

    const totalPrice = (Number(priceWhitelistMint) * howMany).toString();
    const leaf = keccak256(account);
    const proof = getMerkleTree().getHexProof(leaf);

    // here we use code from twitter post of nft
    const method = contract.methods.purchaseTokensWhitelist(howMany, proof, 0);
    // const methodTest = await contract.methods.inWhitelist(account, proof).call();
    // console.log({ methodTest });
    let options = {
      from: account,
      gas: "0",
      value: totalPrice,
    };
    try {
      const estimateGasPrice1 = await method.estimateGas(options);
      const estimateGasPrice2 = Math.trunc(gasFactor * estimateGasPrice1);
      options = { ...options, gas: "" + estimateGasPrice2 };
    } catch (e) {
      let msg;
      try {
        console.log(e.message);
        let a = e.message;
        let objStr = a.substring(a.indexOf("{"), a.lastIndexOf("}") + 1);
        // console.log({ objStr });
        msg =
          JSON.parse(objStr).message ||
          JSON.parse(objStr).originalError.message;
        msg = msg.replace("err: ", "");
        msg = msg.replace("execution reverted: ", "");
      } catch (eiii) {}

      if (!msg || msg === undefined) {
        msg = "Insufficient funds";
      }

      // if (msg === "Mint min 1, max 20")
      //    msg = "Please mint within limits";
      // else if (msg === "send correct eth")
      //    msg = "New Message";

      alert(msg);
      setLoading(false);

      return;
    }

    try {
      setLoading(true);
      await method.send(options).on("confirmation", (i, tx) => {
        if (i === 3) {
          setLoading(false);
          let tokenId;
          try {
            tokenId = tx.events.Transfer.returnValues.tokenId;
          } catch (e) {
            tokenId = tx.events.Transfer[0].returnValues.tokenId;
          }
          if (
            window.confirm(
              "Check your Alpha Aliens on OpenSea. Refresh the page if needed."
            )
          )
            window.location.href = `${os}/${nftAddress}/${tokenId}`;
        }
      });
    } catch (e) {
      setLoading(false);
      alert(e.message);
    }
  });
};

// read api
export const totalSupply = async (setTotalSupply) => {
  getBlockchainData(async (account, web3) => {
    const contract = getContractNft(web3);
    const totalSupply = await contract.methods.totalSupply().call();
    setTotalSupply(totalSupply);
  });
};

export const getMerkleTree = () => {
  return new MerkleTree(whitelist, keccak256, {
    hashLeaves: true,
    sortPairs: true,
  });
};
