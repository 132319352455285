import "./App.css";
import Navbar from "./Component/Navbar/Navbar";
import Header from "./Component/Header/Header";
import Mint from "./Component/Mint/Mint";
import About from "./Component/About/About";
import Team from "./Component/Team/Team";
import Footer from "./Component/Footer/Footer";
import Verified from "./Component/Verified/Verified";
import Founder from "./Component/Founder/Founder";
import Collection from "./Component/Collection/Collection";
import Coinbase from "./Component/Coinbase/Coinbase";
import Animation from "./Component/Animation/Animation";
import Faq from "./Component/Faq/Faq";
import FunArt from "./Component/FunArt/FunArt";
import Strategy from "./Component/Strategy/Strategy";
import Mag from "./Component/Mag/Mag";
import Digital from "./Component/Digital/Digital";
import Power from "./Component/Power/Power";
import Sniping from "./Component/Sniping/Sniping";
import Metaworld from "./Component/Station/Station";
import { getRoot } from "./eth-setup/get-whitelist-root";
getRoot();

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Mint />
      {/* <Coinbase /> */}
      {/* <Founder /> */}
      <About />
      {/* <Sniping /> */}
      {/* <Metaworld /> */}
      <Animation />
      {/* <Strategy /> */}
      {/* <Mag /> */}
      {/* <Digital /> */}
      {/* <FunArt /> */}
      {/* <Vision /> */}
      {/* <Collection /> */}
      {/* <Service /> */}
      <div className="light-background">
        <Power />
        <Team />
        <Faq />
        {/* <Verified /> */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
