import { getMerkleTree } from "./apis.js";
// import keccak256 from 'keccak256';
// import { whitelist } from './smart-contracts-config.js';

export const getRoot = () => {
  const tree = getMerkleTree();
  const root = tree.getHexRoot();
  console.log({ root });

  // Work for UI now
  // check this address available or not in whitelist
  // const account = whitelist[whitelist.length - 1];
  // const leaf = keccak256(account);
  // const proof = tree.getHexProof(leaf);
  // console.log({ address: account }, { proof: proof.length }, JSON.stringify(proof, null, 4));

  // Smart contract call here for mint
  // const verified = tree.verify(proof, leaf, root);
  // console.log({ verified });
};
