import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Animation.css";
const Animation = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    arrows: false,

    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  const [value, getValue] = useState([]);

  useEffect(() => {
    async function getCourses() {
      fetch("../../../animation.json")
        .then((res) => res.json())
        .then((data) => getValue(data));
    }
    getCourses();
  }, []);
  return (
    <div className="container">
      <div className="title-white text-center py-5">COLLECTION</div>
      <Slider {...settings}>
        {value.map((sliders) => (
          <Sliders Slidercard={sliders} id={sliders.id} key={sliders.id}></Sliders>
        ))}
      </Slider>
    </div>
  );
};

export default Animation;

const Sliders = ({ Slidercard }) => {
  const { id, img } = Slidercard;

  return (
    <div className="pb-5">
      <img src={img} alt={id} className="img-fluid w-100 animation" />
    </div>
  );
};
