import React, { useEffect } from "react";
import team1 from "../Image/Team/Team1.png";
import team2 from "../Image/Team/Team2.png";
import team3 from "../Image/Team/Team3.png";
import team4 from "../Image/Team/Team4.png";
import "./Team.css";

const Team = () => {
  return (
    <div className="team" id="team">
      <div className="container ">
        <div className="row">
          <div className="col-11 col-lg-12 mx-auto">
            <div className="title-white text-center py-5">TEAM</div>
            <div className="team-grid pb-5">
              <div
                // data-aos="zoom-in-up"
                // data-aos-easing="ease-in-sine"
                className="team-iteam mx-auto"
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="image-part mb-3">
                    <img src={team1} alt="" className="img-team" />
                  </div>
                  <div className="team-title mb-2">Alpha Zero</div>
                  <div className="team-deatils text-center mb-3">
                    Spaceship Dev
                  </div>
                </div>
              </div>
              <div
                // data-aos="zoom-in-up"
                // data-aos-easing="ease-in-sine"
                className="team-iteam mx-auto"
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="image-part mb-3">
                    <img src={team2} alt="" className="img-team" />
                  </div>
                  <div className="team-title mb-2">Ninukab</div>
                  <div className="team-deatils text-center mb-3">Creator</div>
                </div>
              </div>
              <div
                // data-aos="zoom-in-up"
                // data-aos-easing="ease-in-sine"
                className="team-iteam mx-auto"
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="image-part mb-3">
                    <img src={team3} alt="" className="img-team" />
                  </div>
                  <div className="team-title mb-2">Alien Dean</div>
                  <div className="team-deatils text-center mb-3">
                    Community Manager
                  </div>
                </div>
              </div>
              <div
                // data-aos="zoom-in-up"
                // data-aos-easing="ease-in-sine"
                className="team-iteam mx-auto"
              >
                {/* <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="image-part mb-3">
                    <img src={team4} alt="" className="img-team" />
                  </div>

                  <div className="team-title mb-2">Alien4</div>
                  <div className="team-deatils text-center mb-3">...</div>
                </div> */}
              </div>
              {/* <div
                data-aos="zoom-in-up"
                data-aos-easing="ease-in-sine"
                className="team-iteam mx-auto"
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="image-part mb-3">
                    <img
                      src="https://tattooskullzsociety.com/images/Untitled-Artwork.jpg"
                      alt=""
                      className="img-team"
                    />
                  </div>
                  <div className="team-title mb-2">TENGU</div>
                  <div className="team-deatils text-center mb-3">
                    Graphic Designer & Tattoo Artist
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
