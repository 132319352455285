import React from "react";
import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsMedium } from "react-icons/bs";
import { SiGitbook } from "react-icons/si";
import logo from "../Image/alien0.png";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer">
      {/* <div className="wave-part">
				<div className="wave-footer wave1-footer"></div>
				<div className="wave-footer wave2-footer"></div>
				<div className="wave-footer wave3-footer"></div>
				<div className="wave-footer wave4-footer"></div>
			</div> */}
      <div className="container py-3 py-lg-5">
        <div className="mx-auto d-flex flex-column flex-lg-row pb-3 justify-content-between align-items-center">
          <div className="img-footer mb-2 mb-lg-0 d-flex align-items-center">
            {/* <div className="copyright">ALPHA ALIENS 2022</div> */}
            {/* <img src={logo} alt="" width="70px" className="img-fluid" /> */}
            <div className="text-center copy-right">© 2022 Alpha Aliens</div>
          </div>
          <div className="icon-part d-flex mt-2 mt-lg-0">
            <div className="twitter mx-3">
              <a href="https://twitter.com/AliensAlpha" target="_blank">
                <BsTwitter className="icon-btn" />
              </a>
            </div>
            <div className="discord mx-3">
              <a href="https://discord.com/invite/alphaaliens" target="_blank">
                <FaDiscord className="icon-btn" />
              </a>
            </div>
            <div className="gitbook mx-3">
              <a
                href="https://alphaaliens.gitbook.io/alpha-aliens"
                target="_blank"
              >
                <SiGitbook className="icon-btn" />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="text-center copy-right">© 2022 Alpha Aliens</div> */}
      </div>
    </div>
  );
};

export default Footer;
