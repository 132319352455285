import React from "react";
import "./Power.css";
const Power = () => {
  return (
    <div className="power" id="power">
      <div className="container">
        <div className="row">
          <div className="col-11 col-lg-12 mx-auto">
            <div className="title-white text-center py-5">
              DIAMOND HANDS MECHANISM
            </div>
            <div
              className="subtitle-white width-part mx-auto pb-5"
              // data-aos="fade-up"
            >
              We want to build a project and a community that can look at the
              long term and for this reason we have planned a mechanism that can
              reward and offer benefits in relation to the time you hold your
              Aliens. For example, during the two $ALPHA airdrops planned, a
              member who holds his Alien since minting will get more tokens than
              someone who bought it the day before the snapshot.
            </div>

            {/* <div className="col-lg-7 col-11 mx-auto text-center pb-5">
              <img
                src={header}
                alt=""
                className="img-center mx-auto img-fluid size"
                // data-aos="fade-up"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Power;
