import React, { useState } from "react";
import { questions } from "./api";
import "./faq.css";
import MyAccordion from "./MyAccordion";
const Faq = () => {
	const [data, setData] = useState(questions);
	return (
		<div className="faq">
			<div className="container">
				<a name="faq"></a>

				<div className="col-11 col-lg-12 mx-auto ">
					<div className="title-white mx-auto text-center  py-5">FAQ </div>
					<div className="dark-part mx-auto width-part pb-5">
						<div className="accordion" id="accordionExample">
							{data.map((curElem) => {
								return <MyAccordion key={curElem.id} element={curElem} />;
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Faq;
